<template>
  <b-card>
    <b-row>
      <b-col cols="9">
        <app-collapse class="border-light">
          <app-collapse-item title="O que é o Certificado e-CNPJ A1?" ref="collapseItemA1">
            <p>
              O certificado digital é um documento eletrônico que funciona como a identidade de uma pessoa jurídica. Nele, constam o nome, um número conhecido por chave e outros dados que identificam a empresa na Receita Federal e nos sistemas de gestão. De forma básica, o certificado e-CNPJ A1, é um arquivo de computador, tal arquivo encontra-se instalado no computador utilizado pela empresa, e o seu funcionamento se dá por um par de chaves criptografadas, sendo uma pública, enviada para a Autoridade Certificadora e outra privada, que fica no computador da empresa e é protegida por senha.
            </p>
          </app-collapse-item>

          <app-collapse-item title="E como será utilizado?">
            <p>
              O e-CNPJ A1 será utilizado para a realização de uma análise de crédito que possibilite a viabilização da concessão de empréstimos aos usuários da plataforma, a CreditCorp contrata prestadores que possuem ferramentas adequadas para consulta de informações cadastrais e financeiras dos clientes, sem custo adicional.
            </p>
          </app-collapse-item>

          <app-collapse-item title="Por que isso é necessário?">
            Com o seu certificado o nosso sistema fará a leitura das suas faturas diretamente na Secretaria da Fazenda,
            permitindo uma melhor qualificação do perfil do seu negócio para gerar ofertas ainda melhores para você.
          </app-collapse-item>
          <app-collapse-item title="Isso é seguro?">
            Seus dados serão guardados de forma criptografada e por isso, muito segura. Os dados de acesso serão usados
            unicamente para leitura das suas informações na receita e nunca serão compartilhados com terceiros.
          </app-collapse-item>
          <app-collapse-item title="Qual a vantagem desse processo?">
            Através desse processo não será necessário enviar manualmente o arquivo do seu SPED fiscal, além dos
            arquivos referentes ao seu contas a pagar e receber dos últimos 3 anos, pois isso será feito digitalmente de
            forma bem mais rápida.
          </app-collapse-item>
        </app-collapse>
      </b-col>
      <b-col cols="3">
        <b-card class="padlocks-bg w-100">
          <div class="d-flex flex-column justify-content-center align-items-start p-0 w-100">
            <div class="d-flex flex-row align-items-center justify-content-between p-0 w-100">
              <b-img src="@/assets/images/dashboard/whatsup.svg" fluid />
              <div>
                <h4 class="mb-0">Precisa de ajuda?</h4>
                <small class="text-muted">Chame no WhatsApp</small>
              </div>
              <b-button class="btn btn-sm" target="_blank" href='https://wa.me/551130343417' variant="outline-primary">Chamar</b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BImg, BButton } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'DigitalOfferHelp',
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BImg,
    BButton
  },
}
</script>