<template>
  <b-modal
      id="certificate-password-modal"
      centered no-stacking
      size="sm"
      @ok="handleOk"
      @show="resetModal"
      @hidden="resetModal"
  >
    <b-row align-h="center" align-v="center">
      <b-col sm="12">
        <h4>Informe a senha do seu Certificado Digital</h4>
      </b-col>
    </b-row>
    <b-form>
      <b-form-group
          :state="Boolean(password)"
          invalid-feedback="Senha é obrigatória"
          label="Senha"
          label-for="certificate-password"
      >
        <b-input-group
            class="input-group-merge"
        >
          <b-form-input
              id="certificate-password"
              v-model="password"
              :state="Boolean(password)"
              :type="passwordFieldType"
              class="form-control-merge"
              name="certificate-password"
          />
          <b-input-group-append is-text>
            <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility('passwordFieldType')"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-row align-h="center" align-v="center">
        <b-col cols="12">
          <b-row>
            <b-col cols="6">
              <b-button variant="outline-primary" @click="cancel()">Cancelar</b-button>
            </b-col>
            <b-col cols="6">
              <b-button :disabled="!Boolean(password)" variant="primary" @click="ok()">Confirmar</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {togglePasswordVisibility} from "@core/mixins/ui/forms";
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BRow
} from "bootstrap-vue";

export default {
  name: "CertificatePasswordModal",
  components: {BButton, BCol, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BModal, BRow},
  data() {
    return {
      password: null
    }
  },
  methods: {
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.submitSolicitation()
      this.$nextTick(() => {
        this.$bvModal.hide('certificate-password-modal')
      })
    },
    async submitSolicitation() {
      try {
        this.$swal.showLoading()
        await this.$store.dispatch('dashboard/createDigitalSolicitation', {certificado: this.a1Certificate, senha: this.password})
        this.$swal.fire({
          title: 'Solicitação enviada com sucesso!',
          text: 'Aguarde a confirmação do seu pedido.',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        this.$emit('ask-for-scr')
      } catch (error) {
        const errorMessage = error.response.data.error.base.join(' ') || 'Verifique se o certificado está correto e tente novamente.'
        this.$swal.fire({
          title: 'Erro ao enviar solicitação!',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    resetModal() {
      this.password = null
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    a1Certificate() {
      return this.$store.state.dashboard.linkapitalSolicitation.certificate
    }
  },
  mixins: [togglePasswordVisibility],
}
</script>

<style scoped>

</style>
