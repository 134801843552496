<template>
    <div class="d-flex flex-column align-items-start p-0" style="gap: 4px;">
      <div class="d-flex flex-row align-items-center p-0" style="gap: 8px;">
        <p>
          <b-icon-check2 variant="success" font-scale="1.6"/>
          {{ title }}
        </p>
      </div>
      <p>{{ text }}</p>
    </div>
</template>

<script>
import { BIconCheck2 } from "bootstrap-vue";

export default {
  name: "ListaPositiva",
  props: {
    title: String,
    text: String
  },
  components: {
    BIconCheck2
  }
}
</script>

<style scoped>

</style>
