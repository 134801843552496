<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <b-row class="d-flex flex-row justify-content-between align-items-center">
        <b-col cols="8">
          <h1>Avaliação de crédito</h1>
          <h4>
            Libere sua oferta inicial para visualizar os valores que podem ser disponibilizados para
            suas operações de contratação de fretes.
          </h4>
        </b-col>
        <b-col class="d-flex justify-content-end" cols="4"> </b-col>
      </b-row>
    </b-card>

    <container-form v-if="!askForScr" :showLeftIcon="false" class="mb-2">
      <template #form>
        <div>
          <div class="d-flex flex-row justify-content-start">
            <b-img
              alt="Oferta Digital"
              class="mr-2"
              height="88px"
              src="@/assets/images/dashboard/oferta-digital.svg"
              width="88px"
            />
            <div
              class="d-flex flex-column justify-content-center align-items-start"
              style="gap: 8px"
            >
              <h4>Liberando oferta digitalmente com Certificado Digital</h4>
              <p>
                Para seguir com a sua simulação precisamos que você faça o upload do
                <strong class="text-primary cursor-pointer" v-b-toggle="a1CollapseItemID"
                  >Certificado digital e-CNPJ A1</strong
                >, e nos conceda permissão de acesso ao mesmo, exclusivamente para a leitura de suas
                informações na Receita Federal.
              </p>
            </div>
          </div>
          <hr />
          <div v-if="step === 1" class="d-flex flex-row justify-content-between align-items-center">
            <b-form-checkbox v-model="termAccepted">
              <p class="mb-0">
                Li e aceito as
                <a
                  href="https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/pamcash/Condi%C3%A7%C3%B5es+Gerais+para+Permiss%C3%A3o+de+Utiliza%C3%A7%C3%A3o+de+Certificado+Digital+para+Acesso+%C3%A0s+Informa%C3%A7%C3%B5es+Fiscais.pdf"
                  target="_blank"
                  >Condições Gerais</a
                >
                para permissão de utilização de certificado digital para acesso às informações
                fiscais.
              </p>
            </b-form-checkbox>
            <b-button :disabled="!termAccepted" class="mr-2" variant="primary" @click="step = 2"
              >Continuar</b-button
            >
          </div>
          <div v-if="step === 2">
            <h3 class="mb-1">Envie seu certificado digital</h3>
            <b-row>
              <b-col class="d-flex justify-content-between align-items-end" cols="12">
                <cc-dropzone
                  v-model="file"
                  acceptFormats=".crt, .pfx, .p12"
                  fit
                  subtitle="Envie seu arquivo no formato .CRT, .PFX ou .P12"
                />
                <div>
                  <b-button class="mr-1" variant="outline-primary" @click="showModal"
                    >Cancelar
                  </b-button>
                  <b-button :disabled="!hasCertificate" variant="primary" @click="step = 3"
                    >Continuar</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="step === 3">
            <h3 class="mb-1">Informe a senha do seu certificado digital</h3>
            <b-row>
              <b-col class="d-flex justify-content-between align-items-end" cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="password"
                  rules="required"
                >
                  <b-form-group class="mb-0">
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="certificate-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="certificate-password"
                        placeholder="Senha do certificado"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility('passwordFieldType')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <div>
                  <b-button class="mr-1" variant="outline-primary" @click="showModal"
                    >Cancelar
                  </b-button>
                  <b-button
                    :disabled="!password.length"
                    variant="primary"
                    @click="submitSolicitation"
                    >Continuar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </container-form>

    <scr-permission v-if="askForScr" offer-kind="digital" />

    <digital-offer-help v-if="!askForScr" ref="digitalOfferHelp" />

    <b-modal id="cancell-modal" centered header-bg-variant="white" hide-footer>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h3 class="text-center mt-1">
          Essa etapa é necessária para dar continuidade na contratação. Você tem certeza que deseja
          cancelar esta operação?
        </h3>
        <div class="mt-2 mb-3">
          <b-button class="mr-2" variant="outline-primary" @click="$bvModal.hide('cancell-modal')"
            >Cancelar</b-button
          >
          <b-button variant="primary" @click="modalCancelationContinue">Continuar</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BIconArrowLeft,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import ScrPermission from '@/views/client/dashboard/components/manual_offer/ScrPermission.vue'
import ListaPositiva from '@/views/client/dashboard/components/manual-offer/ListaPositiva'
import CertificatePasswordModal from '@/views/client/dashboard/components/manual-offer/modals/CertificatePasswordModal'
import ContainerForm from '@/views/client/dashboard/components/manual_offer/ContainerForm.vue'
import DigitalOfferHelp from '@/views/client/dashboard/components/DigitalOfferHelp.vue'
import CcDropzone from '@core/components/cc-dropzone/CcDropzone'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required } from '@validations'

export default {
  name: 'DigitalOffer',
  components: {
    BButton,
    ListaPositiva,
    BIconArrowLeft,
    CertificatePasswordModal,
    DigitalOfferHelp,
    ContainerForm,
    ScrPermission,
    BCard,
    BRow,
    BCol,
    BImg,
    BFormCheckbox,
    CcDropzone,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormInput,
  },
  mixins: [togglePasswordVisibility],
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      file: null,
      step: 1,
      password: '',
      termAccepted: false,
      askForScr: true,
      a1CollapseItemID: '',
      required,
    }
  },
  async mounted() {
    await this.checkTermsAcceptance()
    await this.checkScrConsent()
    await this.getA1CollapseID()
  },
  methods: {
    goBack() {
      return this.$router.go(-1)
    },
    openModalTerms() {
      return this.$bvModal.show('modal-terms')
    },
    checkTermsAcceptance() {
      this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {})
    },
    checkScrConsent() {
      if (
        this.ScrConsentStatus !== 'concluido' &&
        ['pendente', 'em_progresso'].includes(this.companyAnalysisStatus)
      )
        this.askForScr = true
    },
    saveBase64() {
      return this.$store.dispatch('dashboard/updateA1Certificate', this.file)
    },
    goToPassword() {
      this.saveBase64()
      this.step = 3
    },
    async submitSolicitation() {
      this.$swal
        .fire({
          title: 'Deseja continuar?',
          text: 'Enviar solicitação de avaliação de crédito.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Enviar!',
        })
        .then(async (result) => {
          if (result.value) {
            this.$swal.showLoading()

            await this.$store.dispatch('dashboard/validateA1Certificate', {
              certificado: this.file,
              senha: this.password,
            })

            await this.$store.dispatch('dashboard/updateA1Certificate', {
              certificado: this.file,
              senha: this.password,
            })
            await this.$swal.fire({
              icon: 'success',
              title: 'Feito!',
              text: 'Agora precisamos do acesso ao SCR para continuar.',
              showConfirmButton: false,
              timer: 1500,
            })
            this.askForScr = true
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response.data.error.base.join(' ') ||
            'Verifique se o certificado está correto e tente novamente.'
          this.$swal.fire({
            title: 'Erro ao enviar solicitação!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    async getA1CollapseID() {
      const { collapseItemID } = await this.$refs.digitalOfferHelp.$refs.collapseItemA1
      this.a1CollapseItemID = collapseItemID
    },
    showModal() {
      this.$bvModal.show('cancell-modal')
    },
    modalCancelationContinue() {
      this.$router.go()
      this.$bvModal.hide('cancell-modal')
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    a1Certificate() {
      return this.$store.state.dashboard.linkapitalSolicitation.certificate
    },
    hasCertificate() {
      return this.file
    },
    ScrConsentStatus() {
      return this.$store.state.auth.userData.empresa.status_scr_consent
    },
    companyAnalysisStatus() {
      return this.$store.state.auth.userData.empresa.status_analise
    },
  },
}
</script>

<style scoped></style>
